import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import TopupList from "./components/TopupList";
import TransactionList from "./components/TransactionList";
import WithdrawList from "./components/WithdrawList";
import { GET_CASH } from "@/graphql/profile";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "WalletList",
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "กระเป๋าเงิน"
    }]);
  },
  created: function created() {
    document.title = "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19 | ".concat(WEBSITE);
  },
  components: {
    TopupList: TopupList,
    TransactionList: TransactionList,
    WithdrawList: WithdrawList
  },
  data: function data() {
    var _this$$route$query$ca = this.$route.query.cat,
        cat = _this$$route$query$ca === void 0 ? 1 : _this$$route$query$ca;
    return {
      page: 1,
      pageSize: 10,
      loading: false,
      status: "",
      search: "",
      cat: Number(cat)
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    pushCat: function pushCat(val) {
      var newpath = "".concat(this.$route.path, "?cat=").concat(val);

      if (this.$route.fullPath !== newpath) {
        this.$router.push({
          name: "wallet",
          query: {
            cat: val
          }
        });
      }
    },
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        case 2:
          return "warning";

        case 3:
          return "success";

        default:
          return "primary";
      }
    },
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return bigDecimal.round(val, decimal);
    }
  },
  watch: {
    "$route.query.cat": function $routeQueryCat(id) {
      this.cat = Number(id) || 1;
    }
  },
  apollo: {
    cash: {
      query: GET_CASH
    }
  }
};