import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;

import gql from "graphql-tag";
export var GET_PROFILE_BANK = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query {\n    bankAccounts {\n      isSuccess\n      message\n      records {\n        _id\n        bank_name\n        branch_name\n        account_no\n        account_name\n        bank_img\n        created_at\n      }\n    }\n  }\n"])));
export var CREATE_PROFILE_BANK = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation(\n    $account_name: String!\n    $account_no: String!\n    $bank_name: String!\n    $branch_name: String\n    $bank_img: String\n  ) {\n    bank_create(\n      input: {\n        account_name: $account_name\n        account_no: $account_no\n        bank_name: $bank_name\n        branch_name: $branch_name\n        bank_img: $bank_img\n      }\n    ) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_PROFILE_BANK = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation(\n    $_id: ID\n    $account_name: String!\n    $account_no: String!\n    $bank_name: String!\n    $branch_name: String\n    $bank_img: String\n  ) {\n    bank_update(\n      input: {\n        _id: $_id\n        account_name: $account_name\n        account_no: $account_no\n        bank_name: $bank_name\n        branch_name: $branch_name\n        bank_img: $bank_img\n      }\n    ) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_PROFILE_BANK = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($_id: ID!) {\n    bank_delete(_id: $_id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var CANCEL_LINE = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation {\n    cancelLine {\n      isSuccess\n      message\n    }\n  }\n"])));
export var GET_CASH = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query {\n    cash {\n      baht\n      rateAlipay\n    }\n  }\n"])));
export var GET_PROFILE = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query {\n    profile {\n      uid\n      full_name\n      userId\n      username\n      password\n      photo_url\n      phone\n      phone2\n      email\n      line\n      cartCount\n      rateOrder\n      verify_file\n      line_token\n      address {\n        _id\n        name\n        phone\n        province\n        amphoe\n        district\n        postcode\n        address\n        default\n      }\n    }\n  }\n"])));
export var GET_PROFILE_ADDRESS = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query {\n    profile {\n      address {\n        _id\n        name\n        phone\n        province\n        amphoe\n        district\n        postcode\n        address\n        default\n      }\n    }\n  }\n"])));
export var UPDATE_PROFILE = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation(\n    $full_name: String\n    $password: String\n    $email: String\n    $phone: String\n    $line: String\n    $verify_filename: String\n  ) {\n    editProfileUser(\n      input: {\n        full_name: $full_name\n        password: $password\n        email: $email\n        phone: $phone\n        line: $line\n        verify_filename: $verify_filename\n      }\n    ) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var CREATE_PROFILE_ADDRESS = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation(\n    $name: String!\n    $phone: String!\n    $address: String!\n    $province: String\n    $amphoe: String\n    $district: String\n    $postcode: String\n    $default: Boolean\n  ) {\n    address_create(\n      input: {\n        name: $name\n        phone: $phone\n        address: $address\n        province: $province\n        amphoe: $amphoe\n        district: $district\n        postcode: $postcode\n        default: $default\n      }\n    ) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_PROFILE_ADDRESS = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation(\n    $_id: ID!\n    $name: String!\n    $phone: String!\n    $address: String!\n    $province: String\n    $amphoe: String\n    $district: String\n    $postcode: String\n    $default: Boolean\n  ) {\n    address_update(\n      input: {\n        _id: $_id\n        name: $name\n        phone: $phone\n        address: $address\n        province: $province\n        amphoe: $amphoe\n        district: $district\n        postcode: $postcode\n        default: $default\n      }\n    ) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_PROFILE_ADDRESS = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation($_id: ID!) {\n    address_delete(_id: $_id) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var CHANGE_PASSWORD = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation($input: PasswordInput!) {\n    changePassword(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));